import React from "react";

const Dots = () => {
  return (
    <div className="md:block hidden">
      <div className="absolute top-52 left-12">
        <div className="spinner spinner1 absolute">
          <div className="double-bounce1"></div>
        </div>
      </div>

      <div className="absolute top-28 right-44">
        <div className="spinner spinner2 absolute">
          <div className="double-bounce1"></div>
        </div>
      </div>

      <div className="absolute bottom-[-3rem] left-32">
        <div className="spinner spinner3 absolute">
          <div className="double-bounce1"></div>
        </div>
      </div>

      <div className="absolute bottom-20 left-80 ">
        <div className="spinner spinner4 absolute">
          <div className="double-bounce1"></div>
        </div>
      </div>

      <div className="absolute top-[45%] right-[-4rem]">
        <div className="spinner spinner5 absolute">
          <div className="double-bounce1"></div>
        </div>
      </div>

      <div className="absolute bottom-[-5rem] right-32">
        <div className="spinner spinner6 absolute">
          <div className="double-bounce1"></div>
        </div>
      </div>
    </div>
  );
};

export default Dots;
