import React from "react";


import LavaLamp from "../components/designs/lavaLamp";

import HomePage from "../components/home/homePage";
import Software from "../components/home/software";
import Hardware from "../components/home/hardware";
import Security from "../components/home/security";

const Home = () => {
  return (
    <>
      <section className="relative min-h-screen">
        <LavaLamp />
        <div className="max-w-screen-2xl mx-auto px-5">
          <HomePage />
        </div>
      </section>
      <Software />
      <Hardware />
      <Security />
     
    </>
  );
};

export default Home;
