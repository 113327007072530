import React from "react";
// import { useRef } from "react";
// import useScrollSnap from "react-use-scroll-snap";
import Dots from "../components/designs/dots";
import Button2 from "../components/general/button2";

const Clients = () => {
  // const scrollRef = useRef(null);
  // useScrollSnap({ ref: scrollRef, duration: 50, delay: 20 });

  let images = [
    {
      img: "qde.png",
      alt: "logo",
    },
    {
      img: "ultimaco.png",
      alt: "logo",
    },
    {
      img: "s2m.png",
      alt: "logo",
    },
    {
      img: "facebook.png",
      alt: "logo",
    },
    {
      img: "finonyx.png",
      alt: "logo",
    },
    {
      img: "epic.png",
      alt: "logo",
    },
    {
      img: "dell.png",
      alt: "logo",
    },
    {
      img: "oracle.webp",
      alt: "logo",
    },
  ];

  let partners = [
    {
      img: "oracle.webp",
      alt: "oracle",
      texts:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      heading: "Oracle",
    },

    {
      img: "dell.png",
      alt: "dell",
      texts:
        " ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      heading: "Dell",
    },
    {
      img: "epic.png",
      alt: "epic",
      texts:
        " ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      heading: "Epic Lanka",
    },
    {
      img: "finonyx.png",
      alt: "finonyx",
      texts:
        " ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      heading: "Finonyx",
    },
    {
      img: "qde.png",
      alt: "qde",
      texts:
        " ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      heading: "Quantum Data Engines",
    },
    {
      img: "ultimaco.png",
      alt: "ultimaco",
      texts:
        " ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      heading: "Ultimaco",
    },
    {
      img: "s2m.png",
      alt: "s2m",
      texts:
        " ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      heading: "S2M",
    },
  ];

  return (
    <>
      <Dots />

      {/* clients */}
      <div className="md:static grid place-items-center pt-20 h-[98vh]">
        <div className="max-w-screen-2xl mx-auto md:grid md:place-items-center clients-grid-cols md:gap-16 2xl:px-0 md:px-10 px-5">
          <div>
            <h1 className="xl:text-[80px] md:text-6xl text-4xl font-bold xl:leading-[100px] ">
              Our Valued <span style={{ color: "#176CA8" }}>Clients</span>
            </h1>
            <p className="text-xl ">
              "Where Client Satisfaction Meets Delight"
            </p>
          </div>
          <div className="md:py-0 py-12 mx-auto table">
            <div className="table-cell md:align-middle align-top">
              <div className="grid md:grid-cols-4 grid-cols-3 gap-x-10 gap-y-16 items-center justify-items-center">
                {images.map((item) => (
                  <img
                    className="max-h-16 inline object-contain "
                    src={require(`../assets/images/${item.img}`)}
                    alt={item.alt}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* partners */}
      <section style={{ backgroundColor: "#176CA8" }}>
        <div className="text-white pt-20">
          <h1 className="md:text-6xl text-4xl font-bold text-center ">
            Our Partners
          </h1>
          <p className="md:text-xl text-xs text-center mb-20">
            "Building Success Together, Every Step of the Way"
          </p>
        </div>

        <div className="lg:grid grid-cols-2 gap-x-10 md:px-36 px-5 ">
          {partners.map((item) => (
            <div className="md:inline-flex mb-20 text-white  ">
              <img
                className="md:w-32 w-28 md:h-32 h-28 bg-neutral-200 rounded-full object-contain p-2"
                src={require(`../assets/images/${item.img}`)}
                alt="logo"
              />
              <div className="pl-4">
                <p className="text-2xl font-bold ">{item.heading} </p>
                <p className=" text-justify pb-2">{item.texts}</p>
                {/* <div className="pb-2">
                      <Button2 link={"/"} text={"Learn More"} />
                    </div> */}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Clients;
