import React, { useState, useEffect } from "react";
import Button2 from "../../components/general/button2";
import Dots from "../../components/designs/dots";
import { FaRegBuilding } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";

const CareersDescription = () => {

  // use of useParams to retrieve id from URL
  const { id } = useParams();
  const [careersDescription, setCareerDescription] = useState([]);

  useEffect(() => {
    fetch("http://localhost:8000/careers")
      .then((res) => res.json())
      .then((data) => setCareerDescription(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div>
      <div className="grid place-content-center bg-fixed bg-center z-[-2] pt-40 pb-24">
        <Dots />
        <h1 className="md:text-7xl text-5xl text-center pb-6 font-bold">
          Join <span className="text-[#176CA8]">Octacore</span>
        </h1>
        <p className="md:w-[40vw] w-[80vw] mx-auto text-center">
          Lorem ipsum dolor sit amet consectetur. Ut in sem fringilla egestas
          scelerisque sagittis consequat ut. Semper proin tortor amet egesta.
        </p>
      </div>
      <section className="bg-[#176CA8] pb-10">
        {careersDescription.map((item, index) => {
          console.log(item.id + ":" + id);
          if (item.id === parseInt(id)) {
            return (
              <div className=" pb-2 w-[80vw] mx-auto text-white pt-16" key={index}>
                <div>
                  <h1 className="text-4xl pb-6 font-bold">{item.title}</h1>
                  <div className="pt-5 mx-auto items-end float-right inline-block">
                    <Button2 link={"/"} text={"Apply Now"} />
                  </div>
                </div>
  
                <div className="flex flex-row gap-8">
                  <div className="flex flex-row gap-1">
                    <FaRegBuilding className="text-2xl" />
                    <p className="">On-site</p>
                  </div>
                  <div className="flex flex-row gap-1">
                    <IoLocationOutline className="text-2xl" />
                    <p className="">Jamal, Kathmandu</p>
                  </div>
                </div>
  
                <div className="w-[75vw] mx-auto pt-4  pb-18 text-white" style={{ borderBlockColor: "#D6D6D6" }}>
                  <div className="py-5">
                    <h1 className="text-2xl font-bold mb-5 ">Requirements</h1>
                    <p className="text-sm font-light">{item.description}</p>
                  </div>
  
                  <div className="py-5">
                    <h1 className="text-2xl font-bold mb-5 ">Qualifications and Education Requirements</h1>
                    <p className="text-sm font-light">{item.description}</p>
                  </div>
  
                  <div className="py-5">
                    <h1 className="text-2xl font-bold mb-5 ">Soft Skills</h1>
                    <p className="text-sm font-light">{item.description}</p>
                  </div>
                </div>
              </div>
            );
          }
          return null; // Returning null for cases where the condition is not met
        })}
        <div className="pt-5 w-[80vw] mx-auto">
          <Button2 link={"/"} text={"Apply Now"} />
        </div>
      </section>
    </div>
  );
  
  
};

export default CareersDescription;
