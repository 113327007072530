import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {

  const socials = [
    {
      link: "https://www.youtube.com/@TravelsChariot",
      icon: <FaLinkedinIn  />,
    },
    {
      link: "https://www.facebook.com/tamichaluxury",
      icon: <FaFacebookF />,
    },
    {
      link: "https://twitter.com/ChariotSG",
      icon: <BsTwitterX />,
    },
   
  ];

  const today = new Date();
  const year = today.getFullYear();

  return (
    <div style={{ backgroundColor: "#DBDBDB" }}>
      <div className="p-8">
      <div className="flex flex-row text-lg place-content-center space-x-5 ">
          {socials.map((social, index) => (
            <Link
              key={index}
              to={social.link}
              target="_blank"
              rel="noreferrer"
              className="rounded-full text-white border-2 border-black  bg-black hover:bg-[#D9D9D9]  hover:text-[#383838]  p-2 hover:transition duration-300 ease-in-out"
            >
              {social.icon}
            </Link>
          ))}
          <div className="md:text-sm text-xs mt-1 self-center">
            <p>© {year}  Octacore Solutions Pvt. Ltd.</p>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Footer;
