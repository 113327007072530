import React, { useContext } from "react";
import { MenuContext } from "react-flexible-sliding-menu";
import { IoMenuOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";

const Nav = () => {
  const { toggleMenu } = useContext(MenuContext);
  const { pathname } = useLocation();

  const navItems = [
    {
      label: "Home",
      path: "/",
    },
    // {
    //   label: "About us",
    //   path: "/",
    // },
    {
      label: "Partners and Clients",
      path: "/clients",
    },
    {
      label: "Our Works",
      path: "/works",
    },
    {
      label: "Careers",
      path: "/careers",
    },
    {
      label: "Contact",
      path: "/contact",
    },
  ];

  return (
    <nav className="fixed w-screen bg-transparent-600 p-6 xl:px-32 lg:px-6 px-4 z-[99999] shadow-lg bg-[#f6f6f66c]">
      <div className="mx-auto flex items-center justify-between mr-6">
        <a href="/">
          <img
            className="md:h-14 h-14"
            alt="chariot logo"
            src={require("../../assets/images/logo.png")}
          />
        </a>

        <ul className="flex space-x-10">
          {navItems.map((item, index) => (
            <div key={index}>
              {pathname === "/" && item.scroll ? (
                <p
                  onClick={item.scroll}
                  className={`cursor-pointer text-[15px] inline text-black`}
                >
                  {item.label}
                </p>
              ) : (
                <a
                  href={item.path}
                  className={`cursor-pointer text-[15px] inline text-black`}
                >
                  {item.label}
                </a>
              )}
            </div>
          ))}
        </ul>

        <button
          onClick={toggleMenu}
          className={`text-4xl block lg:hidden text-black`}
        >
          <IoMenuOutline />
        </button>
      </div>
    </nav>
  );
};

export default Nav;
