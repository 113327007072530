import React from "react";
import bgImg from "../../assets/images/security.png";
import Button from "../general/button1";

const Security = () => {
  let security = [
    {
      img: "asset.png",
      heading: "Discovery",
      text: "Lorem ipsum dolor sit amet consectetur. Odio elit tincidunt elementum mi aliquet. Ut libero dictumst sed congue egestas quis. Tellus sed urna non massa ipsum tristique. Bibendum volutpat ut.",
    },
    {
      img: "vulnerability.png",
      heading: "Vulnerability Management",
      text: "Lorem ipsum dolor sit amet consectetur. Odio elit tincidunt elementum mi aliquet. Ut libero dictumst sed congue egestas quis. Tellus sed urna non massa ipsum tristique. Bibendum volutpat ut.",
    },
    {
      img: "network.png",
      heading: "Network Protection",
      text: "Lorem ipsum dolor sit amet consectetur. Odio elit tincidunt elementum mi aliquet. Ut libero dictumst sed congue egestas quis. Tellus sed urna non massa ipsum tristique. Bibendum volutpat ut.",
    },
    {
      img: "threat.png",
      heading: "Threat Detection",
      text: "Lorem ipsum dolor sit amet consectetur. Odio elit tincidunt elementum mi aliquet. Ut libero dictumst sed congue egestas quis. Tellus sed urna non massa ipsum tristique. Bibendum volutpat ut.",
    },
  ];

  return (
    <section
      className=" md:pt-20 pt-10 px-5 pb-16"
      style={{ backgroundImage: `url(${bgImg})`, backgroundColor: "#176CA8" }}
    >
      <div className="text-black">
        <h1 className="md:text-5xl text-3xl text-white text-center mb-16">
          SECURITY
        </h1>
      </div>

      <div className="lg:grid grid-cols-2 gap-10 max-w-screen-2xl w-[80vw] mx-auto lg:space-y-0 space-y-10">
        {security.map((item) => (
          <div
            className="md:inline-flex rounded-2xl gap-5 shadow-2xl py-6 px-4"
            style={{ backgroundColor: "#F5F5F5" }}
          >
            <img
              className="md:w-40 w-28 md:h-40 h-28 rounded-full object-contain md:p-2 pb-2"
              src={require(`../../assets/images/${item.img}`)}
              alt={item.heading}
            />
            <div className="flex flex-col justify-between ">
              <h1 className="md:text-2xl text-xl font-bold md:mb-0 mb-4 ">
                {item.heading}
              </h1>
              <p className=" md:text-sm text-xs text-gray-700 md:mb-0 mb-4">
                {item.text}
              </p>

              {/* <div>
                
                <Button className="text-xs" link={"/"} text={"Learn More"}>
                  
                </Button>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Security;
