import React from "react";
import "../../styles/scroll.css";
// import Dots from "../designs/dots";

const HomePage = () => {
  return (
    <div className="grid place-items-center min-h-screen">
      <div className="text-center max-w-screen-lg ">
        <h1 className="lg:text-7xl md:text-5xl text-4xl font-bold mb-4 pb-2">
          Providing Comprehensive <br />
          <span className="text-[#176CA8]">Global IT</span> Solutions
        </h1>
        <p className="md:text-lg text-sm">
          Octacore Solutions Pvt. Ltd. was incorporated on 7 March 2017 by a
          group of entrepreneurs with a vision to provide latest and innovative
          ICT solutions to various organizations, business houses, banks, telco
          and other sectors in Nepal.
        </p>

        {/* scroll */}
        <span className="scroll-btn pt-36">
          <p>
            <span class="mouse">
              <span></span>
            </span>
          </p>
          <p>Scroll</p>
        </span>
      </div>
    </div>
  );
};

export default HomePage;
