import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Button from "../general/button2";

function Software() {
  let texts = [
    {
      id: 0,
      img: "softwareDev.jpg",
      heading: "Full Stack Development1",
      text: "Lorem ipsum dolor sit amet consectetur. Ultricies arcu blandit blandit interdum lacus sed. Lorem fringilla augue sagittis quis vehicula pulvinar purus. Gravida id quam montes sed ultrices nunc at. Libero suspendisse diam nec sed sed vivamus libero neque habitant. Arcu amet a arcu vulputate lectus odio nulla. Risus diam dolor scelerisque nunc sed malesuada feugiat. Sit purus pellentesque vestibulum mattis eu. Ut ut fermentum eros dignissim nunc consequat ut at. Sed netus parturient amet libero.",
    },
    {
      id: 1,
      img: "softwareDev.jpg",
      heading: "Full Stack Development2",
      text: "Lorem ipsum dolor sit amet consectetur. Ultricies arcu blandit blandit interdum lacus sed. Lorem fringilla augue sagittis quis vehicula pulvinar purus. Gravida id quam montes sed ultrices nunc at. Libero suspendisse diam nec sed sed vivamus libero neque habitant. Arcu amet a arcu vulputate lectus odio nulla. Risus diam dolor scelerisque nunc sed malesuada feugiat. Sit purus pellentesque vestibulum mattis eu. Ut ut fermentum eros dignissim nunc consequat ut at. Sed netus parturient amet libero.",
    },
    {
      id: 2,
      img: "softwareDev.jpg",
      heading: "Full Stack Development3",
      text: "Lorem ipsum dolor sit amet consectetur. Ultricies arcu blandit blandit interdum lacus sed. Lorem fringilla augue sagittis quis vehicula pulvinar purus. Gravida id quam montes sed ultrices nunc at. Libero suspendisse diam nec sed sed vivamus libero neque habitant. Arcu amet a arcu vulputate lectus odio nulla. Risus diam dolor scelerisque nunc sed malesuada feugiat. Sit purus pellentesque vestibulum mattis eu. Ut ut fermentum eros dignissim nunc consequat ut at. Sed netus parturient amet libero.",
    },
    {
      id: 3,
      img: "softwareDev.jpg",
      heading: "Full Stack Development4",
      text: "Lorem ipsum dolor sit amet consectetur. Ultricies arcu blandit blandit interdum lacus sed. Lorem fringilla augue sagittis quis vehicula pulvinar purus. Gravida id quam montes sed ultrices nunc at. Libero suspendisse diam nec sed sed vivamus libero neque habitant. Arcu amet a arcu vulputate lectus odio nulla. Risus diam dolor scelerisque nunc sed malesuada feugiat. Sit purus pellentesque vestibulum mattis eu. Ut ut fermentum eros dignissim nunc consequat ut at. Sed netus parturient amet libero.",
    },
    {
      id: 4,
      img: "softwareDev.jpg",
      heading: "Full Stack Development5",
      text: "Lorem ipsum dolor sit amet consectetur. Ultricies arcu blandit blandit interdum lacus sed. Lorem fringilla augue sagittis quis vehicula pulvinar purus. Gravida id quam montes sed ultrices nunc at. Libero suspendisse diam nec sed sed vivamus libero neque habitant. Arcu amet a arcu vulputate lectus odio nulla. Risus diam dolor scelerisque nunc sed malesuada feugiat. Sit purus pellentesque vestibulum mattis eu. Ut ut fermentum eros dignissim nunc consequat ut at. Sed netus parturient amet libero.",
    },
  ];

  // carousel
  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={`absolute top-[50%] md:right-[5vw] right-[2vw] translate-x-[50%] cursor-pointer rounded-full text-center content-center text-white border border-white p-4 md:text-2xl text-xs hover:bg-[#4a4a4a28] z-50`}
        style={{ display: "grid" }}
        onClick={onClick}
      >
        <BsArrowRight />
      </div>
    );
  }

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={`absolute top-[50%] md:left-[1vw] left-[-9vw] translate-x-[50%] cursor-pointer rounded-full text-center content-center text-white border border-white p-4 md:text-2xl text-xs hover:bg-[#4a4a4a28] z-50`}
        style={{ display: "grid" }}
        onClick={onClick}
      >
        <BsArrowLeft />
      </div>
    );
  }

  let sliderRef = useRef(null);

  const settings = {
    dots: false,
    arrows: true,
    autoplay: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    slidesToShow: 1,
  };

  return (
    <div
      className="min-h-screen md:pt-20 pt-10 px-5"
      style={{ backgroundColor: "#176CA8" }}
    >
      <div className="text-center text-white">
        <h1 className="md:text-5xl text-3xl pb-3">SOFTWARE DEVELOPMENT</h1>
        <p className="italic md:text-sm text-xs md:pb-8 pb-0">
          "Transforming ideas into digital realities"
        </p>
      </div>

      <div className="slider-container md:p-5 p-0 ">
        <div className="container2 grid-cols-5 place-items-center px-[10vw] hidden lg:grid gap-x-5">
          {texts.map((control, index) => (
            <label
              className="text-center group z-[2]"
              key={index}
              htmlFor={`val${control.id}`}
            >
              <input
                className="hidden"
                onChange={(e) => sliderRef.slickGoTo(e.target.value)}
                type="radio"
                id={`val${control.id}`}
                name="val"
                F
                value={control.id}
              />
              <div class="circle-software group-hover:scale-110 transition ease-in-out delay-150 cursor-pointer"></div>
              <p className="uppercase font-semibold text-white text-sm">
                {control.heading}
              </p>
            </label>
          ))}
        </div>

        <div className="carousel_SD">
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
          >
            {texts.map((item, index) => (
              <div
                key={index}
                className="w-[50vw] mx-auto focus-visible:outline-none"
              >
                <div className="lg:inline-flex grid gap-8 place-items-center">
                  <img
                    className="object-contain p-2 rounded-3xl grayscale"
                    src={require(`../../assets/images/${item.img}`)}
                    alt={item.heading}
                  />
                  <div className="text-[#E2E6E9]">
                    <h3 className="md:text-4xl text-xl uppercase font-bold pb-2">
                      {item.heading}
                    </h3>
                    <p className="md:text-md text-justify pb-8 text-[18px]">
                      {item.text}
                    </p>
                    {/* <div className="pb-2">
                      <Button2 link={"/"} text={"Learn More"} />
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Software;
