import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Images from "../../components/works/images";

function Description() {
  const { id } = useParams();
  const [work, setWork] = useState([]);

  useEffect(() => {
    fetch(`http://localhost:8000/projects`)
      .then((res) => res.json())
      .then((data) => {
        setWork(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  if (!work) {
    return <div>Loading...</div>;
  }

  return work.map((element) => {
    if (element.id === id) {
      return (
        <div className="max-w-screen-2xl px-5 md:px-10 mx-auto">
          <div className=" font-jakarta grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <h1 className="font-extrabold text-[70px]">{element.title}</h1>
            </div>

            <div className="grid grid-cols-1 font-bold text-[25px]">
              <p className="font-medium text-[20px] pb-6">
                <span className="font-bold text-[25px]">Category</span>
                <br />
                {element.tag}
              </p>
              <p className="font-medium text-[20px] pb-6">
                <span className="font-bold text-[25px]">Client</span>
                <br />
                {element.client}
              </p>
              <p className="font-medium text-[20px] pb-6">
                <span className="font-bold text-[25px]">Role </span>
                <br />
                {element.role}
              </p>
              <p className="font-medium text-[20px] pb-6">
                <span className="font-bold text-[25px]">Year</span>
                <br />
                {element.year}
              </p>
            </div>
            <div className="font-regular text-[20px]">
              <p>{element.description}</p>
            </div>
          </div>
          <Images props={element} />
        </div>
      );
    }
  });
}

export default Description;
