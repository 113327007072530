import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Works() {
  const [work, setWork] = useState([]);
  const [displayWork, setDisplayWork] = useState({});
  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    className: "center",
    centerMode: true,
    focusOnSelect: true,
    infinite: true,
    centerPadding: "100px",
    slidesToShow: 3,
    speed: 500,
    afterChange: function (index) {
      setDisplayWork(work[index]);
      setImageIndex(index);
    },
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    // nextArrow: <GalleryNextArrow />,
    // prevArrow: <GalleryPrevArrow />,
  };

  useEffect(() => {
    fetch("http://localhost:8000/projects")
      .then((res) => res.json())
      .then((data) => {
        setWork(data);
        setDisplayWork(data[0]);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="pt-32 h-[95vh] flex flex-col justify-between">
      {/* mx-20 grid grid-cols-2 font-jakarta */}
      <div className="mx-4 grid font-jakarta mid:mx-20 mid:grid-cols-2">
        <div className="text-6xl md:text-8xl font-extrabold pt-20">
          Our <span className="text-[#176CA8]">Works</span>
        </div>
        <div className="text-left py-2 sm:text-right" id="changeable-portion">
          {displayWork && (
            <>
              <p className="font-extralight text-[18px] hidden mid:block">
                {displayWork.description}
              </p>
              <br />
              <h1 className="font-semibold text-3xl md:text-5xl">
                {displayWork.title}
              </h1>
              <p className="font-extralight text-[18px] mid:hidden block">
                {displayWork.description}
              </p>
            </>
          )}
        </div>
      </div>
      <div className="slider-works">
        <Slider {...settings}>
          {work.map((wk, index) => (
            <div className="carousel-div" key={index}>
              <img
                className="carousel-img"
                src={require(`../../assets/images/${wk.src}`)}
                alt={wk.title}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Works;
