import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import Carousel from "../components/carousel";
import Clients from "../pages/clients";
import Navbar from "../components/general/nav";
import NotFound from "../pages/notFound";
import ScrollToTop from "./scrollToTop";
import Careers from "../pages/careers/careers";
import Footer from "../components/general/footer";
import CareersDescription from "../pages/careers/careersDescription";
import Contact from "../pages/contact";
import Works from "../pages/works/works";
import Description from "../pages/works/description";

const Routing = () => {
  const MainLayout = ({ children }) => {
    return (
      <>
        <Navbar />
        <ScrollToTop />
        {children}
        <Footer />
      </>
    );
  };
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <MainLayout>
              <Home />
            </MainLayout>
          }
        />
        <Route exact path="/carousel" element={<Carousel />} />
        <Route
          exact
          path="/clients"
          element={
            <MainLayout>
              <Clients />
            </MainLayout>
          }
        />
        <Route
          exact
          path="/careers"
          element={
            <MainLayout>
              <Careers />
            </MainLayout>
          }
        />
        <Route
          path="/careersdescription/:id"
          element={
            <MainLayout>
              <CareersDescription />
            </MainLayout>
          }
        />

        <Route
          exact
          path="/contact"
          element={
            <MainLayout>
              <Contact />
            </MainLayout>
          }
        />

        <Route
          path="/works"
          element={
            <MainLayout>
              <Works />
            </MainLayout>
          }
        />
        <Route
          path="/category/:tag/:id"
          element={
            <MainLayout>
              <Description />
            </MainLayout>
          }
        />
        {/* default routes for invalid links */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default Routing;
