import React from "react";

function Images({ props }) {
  return (
    <div className="my-auto grid grid-cols-1 gap-4">
      <div className="">
        <img
          src={require(`../../assets/images/${props.primaryImg}`)}
          alt="Project 1 Bg"
          className="rounded-xl object-cover w-[1590px] h-[737px]"
        />
      </div>
      <div className="">
        <img
          src={require(`../../assets/images/${props.secondaryImg}`)}
          alt="Project 3 Bg"
          className="rounded-xl object-cover w-[1590px] h-[737px]"
        />
      </div>
    </div>
  );
}

export default Images;
