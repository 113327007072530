import React, { useState } from "react";
import CategoryGrid from "./categoryGrid";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";

// import { ScrollSpy, initTWE } from "tw-elements";
// initTWE({ ScrollSpy });

function Categories() {
  const [category, setCategory] = useState("All");
  const [circleTop, setCircleTop] = useState(0);

  const setPresentCategory = (category) => {
    setCategory(category);
    const activeCategoryElement = document.querySelector(
      `[data-category="${category}"]`
    );
    if (activeCategoryElement) {
      setCircleTop(activeCategoryElement.offsetTop);
    }
  };

  return (
    <div className="w-full min-h-[95vh] py-10 bg-[#176CA8] z-20">
      <div className="max-w-full px-5 md:px-10">
        <a
          href="!#"
          className="text-white font-jakarta font-normaltext-[20px] flex justify-end sm:justify-end  pt-2 "
        >
          All Categories <LiaLongArrowAltRightSolid className="text-[34px]" />
        </a>
        <div className="relative mx-4 md:flex md:flex-row md:my-8 md:mx-6">
          <span
            className="bg-white w-3 h-3 rounded-full -left-4 align-middle hidden md:block"
            style={{
              position: "absolute",
              top: `${circleTop + 8}px`,
              transition: "top 0.4s ease-in-out", // Smooth transition
            }}
          ></span>
          <div
            className=" font-jakarta font-regular text-[20px] my-4 hidden xsm:flex xsm:justify-around md:flex-col md:basis-1/4 md:self-baseline lg:basis-1/5"
            id="navigation"
          >
            <div
              className={`pb-5 text-left cursor-pointer ${
                category === "All" ? "text-white" : "text-[#f7f7f76c]"
              }`}
              onClick={() => setPresentCategory("All")}
              data-category="All"
            >
              All Categories
            </div>
            <div
              className={`pb-5 text-left cursor-pointer ${
                category === "Software" ? "text-white" : "text-[#f7f7f76c]"
              }`}
              onClick={() => setPresentCategory("Software")}
              data-category="Software"
            >
              Software
            </div>
            <div
              className={`pb-5 text-left cursor-pointer ${
                category === "Hardware" ? "text-white" : "text-[#f7f7f76c]"
              }`}
              onClick={() => setPresentCategory("Hardware")}
              data-category="Hardware"
            >
              Hardware
            </div>
            <div
              className={`pb-5 text-left cursor-pointer ${
                category === "Security" ? "text-white" : "text-[#f7f7f76c]"
              }`}
              onClick={() => setPresentCategory("Security")}
              data-category="Security"
            >
              Security
            </div>
          </div>
          <div className="basis-3/4 lg:basis-4/5 pb-5">
            <CategoryGrid category={category} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Categories;
