import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function CategoryGrid(props) {
  const [works, setWorks] = useState([]);

  useEffect(() => {
    fetch("http://localhost:8000/projects")
      .then((res) => res.json())
      .then((data) => {
        setWorks(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="grid grid-cols-1 xsm:grid-cols-2 w-full mx-2 gap-10 font-jakarta font-normal text-white lg:grid-cols-3">
      {works.map((work, index) => {
        if (props.category === "All") {
          return (
            <Link
              to={`category/${work.tag}/${work.id}`}
              className="relative w-[95%] h-[230px] sm:w-[98%] sm:h-[235px] md:h-[230px] mid:h-[250px] gtmid:h-[270px] xl:h-[300px] 2xl:h-[340px]"
              key={index}
            >
              <p className="text-[32px] absolute inset-0 z-10 bg-[#176CA8] text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-50 duration-300">
                {work.title}
              </p>
              <img
                src={require(`../../assets/images/${work.primaryImg}`)}
                alt={`${work.title} bg`}
                className="object-cover rounded-[45px] relative w-[96%] mx-auto h-[230px] sm:w-full sm:h-[235px] md:h-[230px] mid:h-[250px] gtmid:h-[270px] xl:h-[300px] 2xl:h-[340px] hover:opacity-50"
              />
            </Link>
          );
        } else if (props.category === work.tag) {
          return (
            <Link
              to={`category/${work.tag}/${work.id}`}
              className="relative w-[95%] h-[230px] sm:w-[98%] sm:h-[235px] md:h-[230px]  mid:h-[250px] gtmid:h-[270px] xl:h-[300px] 2xl:h-[340px]"
              key={index}
            >
              <p className="text-[32px] absolute inset-0 z-10 bg-[#176CA8] text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-50 duration-300">
                {work.title}
              </p>
              <img
                src={require(`../../assets/images/${work.primaryImg}`)}
                alt={`${work.title} bg`}
                className="object-cover rounded-[45px] relative w-[95%] h-[230px] sm:w-full sm:h-[260px] md:h-[230px] mid:h-[250px] gtmid:h-[270px] xl:h-[300px] 2xl:h-[340px] hover:opacity-50"
              />
            </Link>
          );
        }
        return null;
      })}
    </div>
  );
}

export default CategoryGrid;
