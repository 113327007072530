import React from "react";
import { BsArrowRight } from "react-icons/bs";

const Button1 = ({ text, link }) => {
  return (
    <a href={link}>
      <button className="primary-button">
        <p>{text}</p>
        <BsArrowRight />
      </button>
    </a>
  );
};

export default Button1;
