import React from "react";
import "../../styles/lava lamp.css";

const LavaLamp = () => {
  return (
    <div className="lamp relative">
      <div className="lava">
        <div className="blob"></div>
        <div className="blob"></div>
        <div className="blob"></div>
        <div className="blob"></div>
        <div className="blob"></div>
        <div className="blob"></div>
        <div className="blob"></div>
        <div className="blob"></div>
        {/* waves */}
        <svg
          className="waves absolute bottom-0"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shape-rendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#176ca8" />
          </g>
        </svg>
        <div className="blob bottom"></div>
      </div>
    </div>
  );
};

export default LavaLamp;
