import React from "react";
import Dots from "../components/designs/dots";
import { Link } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";

const Contact = () => {
  const contacts = [
    {
      title: "Address",
      text: "Siddhi Bhawan, Jamal, Kathmandu",
      href: "https://maps.app.goo.gl/sZuoX9pTL4DeLyqt6",
      icon: <IoLocationOutline />,
    },
    {
      title: "Contact",
      text: "+977-9851234567",
      href: "tel:9851234567",
      icon: <IoCallOutline />,
    },
    {
      title: "Email",
      text: "support@octacore.com.np",
      href: "mailto:support@octacore.com.np",
      icon: <IoMdMail />,
    },
  ];

  return (
    <div>
      <div className="grid place-content-center bg-fixed bg-center z-[-2] pt-40 pb-10">
        <h1 className="md:text-7xl text-5xl text-center pb-6 font-bold">
          Contact <span className="text-[#176CA8]">Us</span>
        </h1>
        <p className="md:w-[40vw] w-[80vw] mx-auto text-center">
          Lorem ipsum dolor sit amet consectetur. Ut in sem fringilla egestas
          scelerisque sagittis consequat ut. Semper proin tortor amet egesta.
        </p>
      </div>

      <section className="md:grid grid-cols-3 gap-8 px-40">
        {contacts.map((contact, index) => (
          <a
            href={contact.href}
            target={contact.title === "Address" ? "_blank" : ""}
            rel="noreferrer"
            key={index}
            className="grid place-content-center relative mt-20 h-60 w-full border-4 border-[#176CA8] rounded-3xl bg-white text-center text-gray-700 "
          >
            <div className="absolute top-0 left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-full text-white text-4xl border-2 bg-[#176CA8] p-4">
              {contact.icon}
            </div>
            <h2 className="mb-3 block font-sans text-2xl font-medium leading-[1.5] tracking-normal text-black antialiased">
              {contact.title}
            </h2>
            <p>{contact.text}</p>
          </a>
        ))}
      </section>
      <div className="parent-circle z-1">
        <div className="semi-circle"></div>
      </div>
    </div>
  );
};

export default Contact;
