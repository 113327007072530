import React, { useState, useEffect } from "react";
import Button2 from "../../components/general/button2";
import DotsBanner from "../../components/designs/dotsBanner";
import { Link } from "react-router-dom";

const Careers = () => {
  const [careers, setCareers] = useState([]);

  useEffect(() => {
    fetch("http://localhost:8000/careers")
      .then((res) => res.json())
      .then((data) => setCareers(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div>
      <div className="grid place-content-center bg-fixed bg-center z-[-2] pt-40 pb-24">
        <div className="">
          <DotsBanner />
        </div>
        <h1 className="md:text-7xl text-5xl text-center pb-6 font-bold">
          Join <span className="text-[#176CA8]">Octacore</span>
        </h1>
        <p className="md:w-[40vw] w-[80vw] mx-auto text-center">
          Lorem ipsum dolor sit amet consectetur. Ut in sem fringilla egestas
          scelerisque sagittis consequat ut. Semper proin tortor amet egesta.
        </p>
      </div>

      <section className="bg-[#176CA8] ">
        <div className=" pb-2 w-[80vw] mx-auto border-b text-white pt-16">
          <h1 className="text-4xl pb-3 font-bold">Current Openings</h1>
          <p className="md:text-xl text-lg pb-5">
            Lorem ipsum dolor sit amet consectetur. Hendrerit praesent ac
            eleifend dictumst. Viverra.
          </p>
        </div>

        <div
          className="w-[75vw] min-h-[28.6vh] mx-auto pt-6 pb-16 text-white"
          style={{ borderBlockColor: "#D6D6D6" }}
        >
          {careers.length > 0 ? (
            <>
              {careers.map((item, index) => (
                <div className="services-banner py-10 border-b m-0" key={index}>
                  <h1 className="text-2xl font-bold mb-5 ">{item.title}</h1>
                  <p className="text-sm font-light">{item.description}</p>
                  <div>
                    <Link to={`/careersdescription/${item.id}`}>
                      <div className="pt-5">
                        <Button2 link={"/"} text={"Learn More"} />
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="rounded-full w-44 h-44 border border-white opacity-50 grid place-content-center">
              <p className="text-center p-4 font-bold">
                No Vacancies at the moment.
              </p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Careers;
