import React from "react";

const Dots = () => {
  return (
    <div className="md:block hidden">
      <div className="absolute top-52 left-12">
        <div className="spinner spinner1 absolute">
          <div className="double-bounce1"></div>
        </div>
      </div>

      <div className="absolute top-28 right-44">
        <div className="spinner spinner2 absolute">
          <div className="double-bounce1"></div>
        </div>
      </div>
    </div>
  );
};

export default Dots;
