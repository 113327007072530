import React from "react";
import WorksCarousel from "../../components/works/worksCarousel";
import Categories from "../../components/works/categories";

const Works = () => {
  return (
    <div>
      {/* <Description />
      <Images /> */}
      <WorksCarousel />
      <div className="relative z-20">
        <Categories />
      </div>
    </div>
  );
};

export default Works;
