import "./App.css";
import "./styles/styles.css";
import Routing from "./utils/routing";

function App() {
  return (
    <div>
      <Routing />
    </div>
  );
}

export default App;
